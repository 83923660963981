// IntelliTrac colors

// primary

$light-primary: #ec6c13;
$light-primary-contrast: #ffffff;

$dark-primary: #ec6c13;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #000000;
$light-accent-contrast: #ffffff;

$dark-accent: #000000;
$dark-accent-contrast: #ffffff;

// common

$page-background: #ec6c132f;
